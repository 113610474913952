@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap&subset=cyrillic");
ul {
  list-style: none; }

* {
  font-family: Montserrat !important;
  box-sizing: border-box;
  outline: none;
  outline-style: none; }

a, button {
  outline: none; }

.no-p {
  padding: 0; }

.no-m {
  margin: 0; }

.center {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center; }

svg {
  width: 100%;
  height: auto; }

.tr-auto {
  width: 100%;
  text-align: right; }

.homeanim {
  margin-top: 20px; }

.full-page__wrap .img-wrapper video {
  object-fit: cover; }

img {
  pointer-events: none; }

.global-wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }

.l-col {
  width: 90px;
  height: 100vh; }

.r-col {
  width: calc(100% - 90px);
  margin-left: 90px; }

.header {
  background-color: #fff;
  position: fixed;
  display: -webkit-flex;
  top: 0;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  height: 100vh;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(142, 156, 170, 0.25);
  justify-content: space-between; }
  .header .menu-o2 {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    .header .menu-o2 span {
      transition: .5s;
      width: 30px;
      display: block;
      height: 1px;
      background-color: #000; }
      .header .menu-o2 span:last-of-type {
        margin-top: 10px; }
  .header .menu-o {
    width: 100%;
    background-color: #FCD100;
    padding: 14px 0 16px;
    text-align: center;
    text-decoration: none;
    cursor: pointer; }
    @media (max-width: 1024px) {
      .header .menu-o {
        width: 83px; } }
    .header .menu-o .txt {
      font-weight: 500;
      font-size: 11px;
      line-height: 100%;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      color: #000000; }
    .header .menu-o .deco {
      display: block;
      margin: 10px auto 15px;
      width: 18px;
      background-color: #000;
      height: 2px;
      position: relative; }
      .header .menu-o .deco:before, .header .menu-o .deco:after {
        content: '';
        position: absolute;
        display: block;
        top: 5px;
        width: 50%;
        background-color: #000;
        height: 2px;
        left: 0; }
      .header .menu-o .deco:after {
        top: 10px;
        width: 100%; }
  .header .menu-open span:first-of-type {
    margin-top: 10px;
    transform: rotate(-45deg); }
  .header .menu-open span:last-of-type {
    margin-top: 0;
    transform: rotate(45deg); }
  .header .logo {
    margin: 15px 0 15px; }
    @media (max-width: 1024px) {
      .header .logo {
        display: none !important; } }
  .header .mobicon {
    width: 149px; }
  .header .logo, .header .menu-o {
    display: block; }
  .header .header__ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%; }
    .header .header__ul li {
      writing-mode: tb-rl;
      transform: rotate(180deg);
      white-space: nowrap;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 33px; }
      .header .header__ul li a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.55);
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        transition: .5s;
        border-radius: 50px;
        padding: 5px 10px; }
        .header .header__ul li a:hover {
          background: rgba(0, 0, 0, 0.07); }
        .header .header__ul li a .ic {
          font-size: 25px;
          margin-bottom: 8px; }
  .header .header-head {
    flex: 0 0 170px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    @media (max-width: 1024px) {
      .header .header-head {
        display: flex;
        justify-content: space-between;
        flex: 0 0 100%;
        padding: 0 20px 0 0 !important; } }
    .header .header-head .burgerwrp {
      width: 100%; }
    .header .header-head .mobicon {
      display: none; }
      @media (max-width: 1024px) {
        .header .header-head .mobicon {
          display: block; } }
    .header .header-head .mobcontactbtn {
      display: none;
      cursor: pointer; }
      @media (max-width: 1024px) {
        .header .header-head .mobcontactbtn {
          display: block; } }
  .header .header-body {
    height: 80%;
    width: 100%;
    overflow-y: auto; }
  .header .ul__social {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .header .ul__social li {
      width: 36px;
      height: 36px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.07); }
      .header .ul__social li a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.5); }

#fp-nav ul li, .fp-slidesNav ul li {
  width: 8px;
  height: 8px; }

#fp-nav ul li a span, .fp-slidesNav ul li a span, #fp-nav ul li a.active span {
  width: 8px;
  height: 8px;
  font-size: 0;
  margin: 0;
  border: 1px solid #c4c4c4;
  background-color: #fff; }

#fp-nav ul li a.active span {
  background-color: #000;
  border-color: #000;
  outline: none;
  pointer-events: none; }

.privacy {
  width: 320px;
  height: auto;
  position: fixed;
  bottom: 20px;
  left: 30px;
  z-index: 1000;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(50, 50, 93, 0.22); }
  @media (max-width: 425px) {
    .privacy {
      bottom: 0;
      left: 0;
      width: 100vw;
      border-radius: 0;
      box-shadow: 0px 8px 20px rgba(50, 50, 93, 0.22), inset 0px 1px 0px rgba(0, 0, 0, 0.1); } }
  .privacy .btn {
    margin: 0; }
  .privacy .close {
    width: 31px;
    height: 31px;
    background: rgba(0, 0, 0, 0.07);
    color: #000;
    font-size: 30px;
    text-decoration: none;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: relative;
    cursor: pointer; }
    .privacy .close::before {
      content: "";
      width: 18px;
      height: 2px;
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg); }
    .privacy .close::after {
      content: "";
      width: 18px;
      height: 2px;
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg); }
  .privacy .pri {
    border: 1px solid red; }
    .privacy .pri-head {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 27px 33px 14px 33px; }
      @media (max-width: 425px) {
        .privacy .pri-head {
          display: none; } }
      .privacy .pri-head img {
        height: 40px;
        width: 40px; }
    .privacy .pri-body {
      padding: 0px 33px; }
      .privacy .pri-body .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.9); }
      .privacy .pri-body .desc {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 160%;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.7); }
    .privacy .pri-footer {
      height: 82px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      align-items: center;
      background: rgba(0, 0, 0, 0.03); }
      .privacy .pri-footer a {
        cursor: pointer; }

ul.ulinfuuter a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none; }

.contactwidget {
  width: 270px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(25, 10, 56, 0.19), 0px 4px 8px rgba(25, 10, 56, 0.18);
  position: fixed;
  z-index: 1000;
  top: 15px;
  right: 10px; }
  .contactwidget .top {
    padding: 10px 10px 16px; }
  .contactwidget .bot {
    padding: 5px 10px 16px;
    background: #F2F2F2;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05); }
  .contactwidget .hd {
    display: flex;
    justify-content: flex-end; }
    .contactwidget .hd .close {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.1);
      position: relative;
      cursor: pointer; }
      .contactwidget .hd .close:before, .contactwidget .hd .close:after {
        content: '';
        position: absolute;
        width: 60%;
        height: 1px;
        background-color: #fff;
        top: 50%;
        left: 50%; }
      .contactwidget .hd .close:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      .contactwidget .hd .close:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
  .contactwidget .tl {
    display: flex;
    text-decoration: none;
    padding: 10px 0; }
    .contactwidget .tl .icowrp {
      display: block;
      border: 7px solid #FCD100;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      color: #FCD100;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 15px; }
    .contactwidget .tl > div {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .contactwidget .tl > div span:nth-child(1) {
        font-size: 13px;
        line-height: 100%;
        letter-spacing: -0.03em;
        color: #313131; }
      .contactwidget .tl > div span:nth-child(2) {
        font-weight: 500;
        font-size: 22px;
        line-height: 100%;
        letter-spacing: -0.03em;
        color: #313131;
        margin-top: 5px; }
  .contactwidget .mail {
    display: flex;
    text-decoration: none;
    padding: 10px 0; }
    .contactwidget .mail .icowrp {
      display: block;
      background-color: #B0B0B0;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 15px; }
    .contactwidget .mail > div {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .contactwidget .mail > div span:nth-child(1) {
        text-transform: none;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.03em;
        color: #313131; }
  .contactwidget .requestcall {
    margin-top: 5px;
    overflow: hidden; }
    .contactwidget .requestcall .hd {
      display: flex;
      justify-content: space-between;
      align-items: flex-end; }
      .contactwidget .requestcall .hd img {
        width: 70px; }
    .contactwidget .requestcall textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      max-height: 300px;
      min-height: 95px;
      padding: 8px 14px;
      border: none;
      margin-top: 10px;
      font-size: 14px;
      line-height: 160%;
      color: #313131;
      border-bottom: 2px solid #FCD100; }
    .contactwidget .requestcall input {
      width: 100%;
      padding: 8px 14px;
      border: none;
      margin-top: 10px;
      font-size: 14px;
      line-height: 160%;
      color: #313131;
      border-bottom: 2px solid #FCD100; }
    .contactwidget .requestcall label.error {
      font-size: 10px;
      color: red;
      margin-top: -10px;
      padding-left: 20px;
      transition: 0.3s ease;
      user-select: none; }
      .contactwidget .requestcall label.error.show {
        opacity: 1; }
    .contactwidget .requestcall button {
      padding: 13px 0;
      margin-top: 16px;
      background-color: #FCD100;
      border-radius: 50px;
      font-weight: 500;
      font-size: 11px;
      line-height: 134.77%;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #000000;
      width: 100%;
      border: none;
      cursor: pointer; }
    .contactwidget .requestcall .success-message > div {
      display: flex;
      align-items: center;
      padding: 16px 0; }
      .contactwidget .requestcall .success-message > div span {
        flex: 0 0 44px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #FCD100;
        margin-right: 19px; }
      .contactwidget .requestcall .success-message > div p {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: #313131;
        margin: 0; }
    .contactwidget .requestcall .success-message > p {
      font-size: 13px;
      line-height: 140%;
      letter-spacing: -0.045em;
      color: #313131;
      margin: 0;
      text-align: justify; }

@media (max-width: 1024px) {
  .mob-hide {
    display: block; }
  .header {
    width: 100%;
    height: unset;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .header .header__ul, .header .ul__social {
      flex-direction: row; }
    .header .header-body, .header .header-head {
      height: unset; }
    .header .header__ul li {
      writing-mode: unset;
      transform: rotate(0deg); }
    .header .menu-o, .header .logo, .header .header__ul li {
      margin-bottom: 0; }
    .header .header-body {
      display: none; }
    .header .header-head {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      padding: 0px 20px;
      flex-direction: row;
      width: 100%;
      justify-content: space-between; } }

@media (min-height: 610px) and (max-height: 625px) {
  .header-body {
    display: none; }
  .menu-body .menu__ul li a {
    padding: 9px 26px !important; } }

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 120px;
  position: relative; }
  @media (max-width: 1024px) {
    .main {
      padding-top: 20px !important; } }
  .main .log-reg-main {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    display: flex !important;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;
    position: absolute;
    z-index: 100;
    right: 20px;
    top: 0; }
    .main .log-reg-main a.cab {
      text-transform: uppercase;
      text-decoration: none;
      border-radius: 50px;
      padding: 9px 13px;
      border: 1px solid #FCD100;
      color: rgba(0, 0, 0, 0.8);
      margin-left: 20px;
      transition: .1s;
      display: flex;
      align-items: center; }
      @media (max-width: 1024px) {
        .main .log-reg-main a.cab {
          display: none; } }
      .main .log-reg-main a.cab .ic2-ic-user {
        font-size: 26px;
        margin-right: 7px;
        color: #D7D7D7;
        transition: 0.3s ease; }
      .main .log-reg-main a.cab .ic2-ic-exit {
        font-size: 14px;
        margin-right: 7px;
        color: #D7D7D7;
        transition: 0.1s ease; }
      .main .log-reg-main a.cab:hover .ic2-ic-user, .main .log-reg-main a.cab:hover .ic2-ic-exit {
        color: #434343; }
      .main .log-reg-main a.cab:hover {
        background-color: #FCD100;
        border: 1px solid #FCD100; }
    .main .log-reg-main .contact {
      border: 1px solid #FCD100;
      box-sizing: border-box;
      border-radius: 50px;
      position: relative;
      text-decoration: none;
      min-height: 43px;
      display: flex;
      align-items: center;
      padding: 0 54px 0 25px;
      margin-right: 20px;
      animation: contactsha 1s ease-out infinite;
      position: relative;
      cursor: pointer; }
      @media (max-width: 1024px) {
        .main .log-reg-main .contact {
          animation: unset;
          border: none;
          box-shadow: none;
          width: 1px;
          height: 1px;
          margin: 0;
          padding: 0; } }

@keyframes contactsha {
  0% {
    box-shadow: 0 0 0 0 rgba(252, 209, 0, 0.6); }
  100% {
    box-shadow: 0 0 15px 20px rgba(252, 209, 0, 0); } }
      .main .log-reg-main .contact .label {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 100%;
        letter-spacing: -0.03em;
        color: #313131;
        background-color: #fff;
        padding: 3px 5px;
        position: absolute;
        top: 0;
        left: 21px;
        transform: translate(0, -50%); }
        @media (max-width: 1024px) {
          .main .log-reg-main .contact .label {
            display: none; } }
      .main .log-reg-main .contact .phone {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        letter-spacing: -0.03em;
        color: #313131; }
        @media (max-width: 1024px) {
          .main .log-reg-main .contact .phone {
            display: none; } }
      .main .log-reg-main .contact > .dots {
        background-color: #313131;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        margin: 0 13px;
        position: relative; }
        @media (max-width: 1024px) {
          .main .log-reg-main .contact > .dots {
            display: none; } }
        .main .log-reg-main .contact > .dots:before, .main .log-reg-main .contact > .dots:after {
          content: "";
          position: absolute;
          background-color: #313131;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          left: 0; }
        .main .log-reg-main .contact > .dots:before {
          top: -5px; }
        .main .log-reg-main .contact > .dots:after {
          bottom: -5px; }
      .main .log-reg-main .contact .dogo {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(26px, 1px); }
        @media (max-width: 1024px) {
          .main .log-reg-main .contact .dogo {
            display: none; } }
        .main .log-reg-main .contact .dogo .dots {
          position: absolute;
          top: 20px;
          right: 22px;
          transform: translate(50%, -50%);
          display: flex;
          justify-content: center;
          align-items: center; }
  .main .logo {
    margin-bottom: 35px;
    display: block;
    width: 198px;
    height: 40px; }
  .main-text {
    position: relative;
    z-index: 2; }
    .main-text .message {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 249.5%;
      letter-spacing: -0.01em;
      color: #FFFFFF;
      background-color: #5B5A5A;
      border-radius: 2px;
      padding: 9px 19px 8px; }
      .main-text .message b {
        font-weight: 600;
        font-size: 42px;
        line-height: 100%;
        letter-spacing: -0.01em;
        color: #FCD100; }
    .main-text .h1wrap {
      display: flex;
      align-items: center; }
      @media (max-width: 991px) {
        .main-text .h1wrap {
          flex-direction: column;
          padding-bottom: 30px; } }
      .main-text .h1wrap a {
        margin-left: 30px; }
        @media (max-width: 991px) {
          .main-text .h1wrap a {
            align-self: center;
            margin: 0; } }
    .main-text .showcase .people, .main-text .showcase .busines {
      display: flex;
      justify-content: space-between;
      padding: 16px 16px 16px 24px;
      background-position: center;
      background-size: cover; }
      @media (max-width: 991px) {
        .main-text .showcase .people, .main-text .showcase .busines {
          flex-direction: column; } }
      .main-text .showcase .people .item, .main-text .showcase .busines .item {
        flex: 0 0 calc(25% - 22px);
        height: 178px;
        display: flex;
        flex-direction: column;
        text-decoration: none; }
        @media (max-width: 991px) {
          .main-text .showcase .people .item, .main-text .showcase .busines .item {
            flex: unset;
            height: unset; } }
        @media (max-width: 991px) {
          .main-text .showcase .people .item + .item, .main-text .showcase .busines .item + .item {
            margin-top: 1rem; } }
        .main-text .showcase .people .item:first-child, .main-text .showcase .busines .item:first-child {
          flex: 0 0 calc(25% - 11px); }
        .main-text .showcase .people .item:last-child, .main-text .showcase .busines .item:last-child {
          flex: 0 0 calc(25% - 11px); }
        .main-text .showcase .people .item.title, .main-text .showcase .busines .item.title {
          justify-content: space-between; }
          @media (max-width: 991px) {
            .main-text .showcase .people .item.title, .main-text .showcase .busines .item.title {
              flex-direction: row;
              justify-content: flex-start; } }
          .main-text .showcase .people .item.title img, .main-text .showcase .busines .item.title img {
            align-self: flex-start; }
            @media (max-width: 991px) {
              .main-text .showcase .people .item.title img, .main-text .showcase .busines .item.title img {
                width: 63px; } }
          .main-text .showcase .people .item.title span, .main-text .showcase .busines .item.title span {
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 140%;
            color: #181818;
            position: relative; }
            @media (max-width: 991px) {
              .main-text .showcase .people .item.title span, .main-text .showcase .busines .item.title span {
                margin-left: 20px;
                margin-top: 20px; } }
            .main-text .showcase .people .item.title span:before, .main-text .showcase .busines .item.title span:before {
              content: '';
              background-color: #FCD100;
              height: 2px;
              width: 40px;
              position: absolute;
              top: -10px;
              left: 0; }
        .main-text .showcase .people .item.tarifitem, .main-text .showcase .busines .item.tarifitem {
          background-color: #F6F6F6;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 16px 16px 16px 0;
          transition: 0.3s ease;
          position: relative; }
          @media (max-width: 991px) {
            .main-text .showcase .people .item.tarifitem, .main-text .showcase .busines .item.tarifitem {
              padding: 0px 0px 0px 0; } }
          .main-text .showcase .people .item.tarifitem:hover, .main-text .showcase .busines .item.tarifitem:hover {
            background-color: #D4D4D4; }
            .main-text .showcase .people .item.tarifitem:hover .lnk > div .ic-electro, .main-text .showcase .people .item.tarifitem:hover .lnk > div .ic-gas, .main-text .showcase .busines .item.tarifitem:hover .lnk > div .ic-electro, .main-text .showcase .busines .item.tarifitem:hover .lnk > div .ic-gas {
              color: #000; }
            .main-text .showcase .people .item.tarifitem:hover .lnk > div .ic2-fileico, .main-text .showcase .busines .item.tarifitem:hover .lnk > div .ic2-fileico {
              color: #000; }
          .main-text .showcase .people .item.tarifitem:not([href]), .main-text .showcase .busines .item.tarifitem:not([href]) {
            pointer-events: none; }
            .main-text .showcase .people .item.tarifitem:not([href]) .title, .main-text .showcase .busines .item.tarifitem:not([href]) .title {
              background-color: transparent; }
            .main-text .showcase .people .item.tarifitem:not([href]):hover, .main-text .showcase .busines .item.tarifitem:not([href]):hover {
              background-color: #F6F6F6; }
          .main-text .showcase .people .item.tarifitem .title, .main-text .showcase .busines .item.tarifitem .title {
            background: #FCD100;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 134.77%;
            letter-spacing: -0.01em;
            color: #000000;
            padding: 8px 15px; }
          .main-text .showcase .people .item.tarifitem .comingsoon, .main-text .showcase .busines .item.tarifitem .comingsoon {
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: #000000;
            padding: 5px 6px 4px;
            display: inline-block;
            border-radius: 50px;
            position: absolute;
            top: 70px;
            right: 16px; }
            @media (max-width: 991px) {
              .main-text .showcase .people .item.tarifitem .comingsoon, .main-text .showcase .busines .item.tarifitem .comingsoon {
                position: static;
                align-self: flex-end;
                margin-right: 16px; } }
            .main-text .showcase .people .item.tarifitem .comingsoon.el, .main-text .showcase .busines .item.tarifitem .comingsoon.el {
              background: #FCD100; }
          .main-text .showcase .people .item.tarifitem .lnk, .main-text .showcase .busines .item.tarifitem .lnk {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 16px; }
            @media (max-width: 991px) {
              .main-text .showcase .people .item.tarifitem .lnk, .main-text .showcase .busines .item.tarifitem .lnk {
                padding: 14px 14px 14px 0; } }
            .main-text .showcase .people .item.tarifitem .lnk > div, .main-text .showcase .busines .item.tarifitem .lnk > div {
              font-weight: 500;
              font-size: 12px;
              line-height: 134.77%;
              letter-spacing: 0.03em;
              text-transform: uppercase;
              color: #000000;
              display: flex;
              align-items: center; }
              @media (max-width: 991px) {
                .main-text .showcase .people .item.tarifitem .lnk > div, .main-text .showcase .busines .item.tarifitem .lnk > div {
                  margin-left: 16px; } }
              .main-text .showcase .people .item.tarifitem .lnk > div .ic-electro, .main-text .showcase .people .item.tarifitem .lnk > div .ic-gas, .main-text .showcase .busines .item.tarifitem .lnk > div .ic-electro, .main-text .showcase .busines .item.tarifitem .lnk > div .ic-gas {
                font-size: 24px;
                color: #BAB9B9;
                transition: 0.3s ease; }
              .main-text .showcase .people .item.tarifitem .lnk > div .ic2-fileico, .main-text .showcase .busines .item.tarifitem .lnk > div .ic2-fileico {
                font-size: 21px;
                margin-right: 7px;
                color: #BAB9B9;
                transition: 0.3s ease; }
            .main-text .showcase .people .item.tarifitem .lnk .ic-arrow, .main-text .showcase .busines .item.tarifitem .lnk .ic-arrow {
              font-size: 6px;
              color: #000000;
              transition: 0.3s ease; }
    .main-text .showcase > div:nth-child(1) {
      border-radius: 15px 15px 0 0; }
    .main-text .showcase > div:nth-child(2) {
      border-radius: 0 0 15px 15px; }
    .main-text .showcase .busines .item.title span {
      color: #EFEFEF; }
    .main-text .showcase .busines .item.tarifitem {
      background-color: #696969; }
      .main-text .showcase .busines .item.tarifitem:hover {
        background-color: #747373; }
        .main-text .showcase .busines .item.tarifitem:hover .lnk > div .ic-electro, .main-text .showcase .busines .item.tarifitem:hover .lnk > div .ic-gas {
          color: #fff; }
        .main-text .showcase .busines .item.tarifitem:hover .lnk > div .ic2-fileico {
          color: #fff; }
        .main-text .showcase .busines .item.tarifitem:hover .lnk .ic-arrow {
          color: #FCD100; }
      .main-text .showcase .busines .item.tarifitem .lnk > div {
        color: #FFFFFF; }
      .main-text .showcase .busines .item.tarifitem .lnk .ic-arrow {
        color: #fff; }
  .main .bgelement {
    height: 430px;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1; }
    .main .bgelement:before {
      content: '';
      position: absolute;
      bottom: -2px;
      right: 0;
      top: -2px;
      left: 0;
      background-image: url("/img/front/svg/wavetop.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center top; }
    .main .bgelement:after {
      content: '';
      position: absolute;
      bottom: -2px;
      right: 0;
      top: -2px;
      left: 0;
      background-image: url("/img/front/svg/wavebot.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center bottom; }

.home-video-modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; }
  .home-video-modal .close-modal {
    cursor: pointer; }
  .home-video-modal iframe {
    width: 68vw; }

.howbecomeclient {
  margin-top: 20px;
  padding-bottom: 55px; }
  .howbecomeclient .becomeclientsteps {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .howbecomeclient .becomeclientsteps {
        flex-wrap: wrap; } }
    .howbecomeclient .becomeclientsteps .item {
      flex: 0 0 calc(25% - 25px); }
      @media (max-width: 991px) {
        .howbecomeclient .becomeclientsteps .item {
          flex: 0 0 calc(50% - 25px);
          margin-top: 20px; } }
      @media (max-width: 600px) {
        .howbecomeclient .becomeclientsteps .item {
          flex: 0 0 100%; } }
      .howbecomeclient .becomeclientsteps .item img {
        width: 100%;
        display: block; }
      .howbecomeclient .becomeclientsteps .item .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 170%;
        color: #1A1A1A;
        margin: 15px 0 0; }
        .howbecomeclient .becomeclientsteps .item .title .blue, .howbecomeclient .becomeclientsteps .item .title .accent {
          color: #03A9D2; }
      .howbecomeclient .becomeclientsteps .item .txt {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        color: #6F6F6F;
        margin: 8px 0 0; }
  .howbecomeclient .becomeclientvids {
    display: flex;
    justify-content: space-between;
    margin-top: 55px; }
    @media (max-width: 988px) {
      .howbecomeclient .becomeclientvids {
        flex-wrap: wrap; } }
    .howbecomeclient .becomeclientvids .item {
      flex: 0 0 calc(50% - 15px);
      border-radius: 6px;
      padding: 24px; }
      @media (max-width: 988px) {
        .howbecomeclient .becomeclientvids .item {
          flex: unset;
          width: 100%;
          padding: 24px 0; } }
      @media (max-width: 988px) {
        .howbecomeclient .becomeclientvids .item + .item {
          margin-top: 20px; } }
      .howbecomeclient .becomeclientvids .item .hd {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 988px) {
          .howbecomeclient .becomeclientvids .item .hd {
            margin: 0 24px; } }
        .howbecomeclient .becomeclientvids .item .hd p {
          font-size: 24px;
          line-height: 120%;
          letter-spacing: -0.025em;
          color: #1A1A1A;
          position: relative; }
          .howbecomeclient .becomeclientvids .item .hd p:before {
            content: '';
            background-color: #FCD100;
            height: 2px;
            width: 40px;
            position: absolute;
            top: -20px;
            left: 0; }
        @media (max-width: 988px) {
          .howbecomeclient .becomeclientvids .item .hd img {
            width: 66px; } }
      .howbecomeclient .becomeclientvids .item .bglnk {
        height: 150px;
        background-size: cover;
        background-position: center;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 16px;
        position: relative;
        cursor: pointer; }
        .howbecomeclient .becomeclientvids .item .bglnk:before {
          content: "";
          position: absolute;
          z-index: 1;
          top: -2px;
          bottom: -2px;
          right: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.3); }
        .howbecomeclient .becomeclientvids .item .bglnk * {
          position: relative;
          z-index: 2; }
        .howbecomeclient .becomeclientvids .item .bglnk > span {
          font-size: 20px;
          line-height: 134.77%;
          color: #000000;
          padding: 8px 25px 10px 13px;
          background: #FCD100;
          margin-top: 16px;
          align-self: start; }
          @media (max-width: 988px) {
            .howbecomeclient .becomeclientvids .item .bglnk > span {
              margin-top: 0; } }
        .howbecomeclient .becomeclientvids .item .bglnk .bot {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 24px;
          padding-left: 24px;
          padding-right: 24px; }
          .howbecomeclient .becomeclientvids .item .bglnk .bot .sub {
            font-weight: 500;
            font-size: 14px;
            line-height: 134.77%;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #FFFFFF; }
          .howbecomeclient .becomeclientvids .item .bglnk .bot .playbtn {
            width: 41px;
            height: 41px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            position: relative; }
            .howbecomeclient .becomeclientvids .item .bglnk .bot .playbtn:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 0;
              height: 0;
              border-radius: 50%;
              opacity: 0.8;
              transition: 0.3s ease; }
            .howbecomeclient .becomeclientvids .item .bglnk .bot .playbtn .ic2-play {
              color: #000;
              font-size: 10px;
              margin-left: 3px; }
      .howbecomeclient .becomeclientvids .item:hover .bglnk .bot .playbtn:before {
        background-color: #fff;
        width: 140%;
        height: 140%; }
      .howbecomeclient .becomeclientvids .item .reglnk {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 100%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #1A1A1A;
        padding: 16px 24px;
        margin-top: 16px;
        border-radius: 50px;
        border: 1px solid #BDBDBD;
        display: inline-block;
        text-decoration: none;
        transition: 0.3s ease; }
        @media (max-width: 988px) {
          .howbecomeclient .becomeclientvids .item .reglnk {
            margin: 16px 24px 0; } }
        .howbecomeclient .becomeclientvids .item .reglnk .ic-arrow {
          font-size: 6px;
          margin-left: 16px;
          vertical-align: middle;
          margin-bottom: 5px;
          display: inline-block; }
    .howbecomeclient .becomeclientvids .people {
      background: #ECECEC; }
      .howbecomeclient .becomeclientvids .people .reglnk:hover {
        border: 1px solid #8E8E8E; }
    .howbecomeclient .becomeclientvids .busines {
      background: #5B5A5A; }
      .howbecomeclient .becomeclientvids .busines .hd p {
        color: rgba(255, 255, 255, 0.9); }
      .howbecomeclient .becomeclientvids .busines .reglnk {
        color: #DEDEDE;
        border-color: #7C7B7B; }
        .howbecomeclient .becomeclientvids .busines .reglnk:hover {
          border: 1px solid #8C8B8B; }

.section-h4.hide {
  color: transparent; }

.viewbideo1 {
  background-color: #F6F6F6;
  padding: 68px 0 25px;
  position: relative; }
  .viewbideo1 .container {
    position: relative;
    z-index: 2; }
  .viewbideo1:before {
    content: '';
    background-image: url(/img/front/svg/wavetop.svg);
    background-repeat: no-repeat;
    background-position: center top;
    position: absolute;
    z-index: 1;
    top: -2px;
    bottom: -2px;
    left: 0;
    right: 0; }
  .viewbideo1 .imgwrp {
    display: flex;
    position: relative; }
    @media (max-width: 758px) {
      .viewbideo1 .imgwrp {
        flex-direction: column; } }
    .viewbideo1 .imgwrp img {
      width: 71%; }
      @media (max-width: 758px) {
        .viewbideo1 .imgwrp img {
          width: 100%; } }
    .viewbideo1 .imgwrp .lnk {
      position: absolute;
      background-color: #FCD100;
      bottom: 0;
      right: 0;
      width: calc(29% + 25px);
      border-radius: 35px 0px 6px;
      height: 100%;
      padding: 75px 24px 43px 65px;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      flex-direction: column; }
      @media (max-width: 758px) {
        .viewbideo1 .imgwrp .lnk {
          position: static;
          width: 100%;
          border-radius: 0;
          padding: 24px; } }
      .viewbideo1 .imgwrp .lnk .txt {
        font-size: 23px;
        line-height: 140%;
        color: #000000; }
      .viewbideo1 .imgwrp .lnk .playbtn {
        border-radius: 50%;
        width: 60px;
        flex: 0 0 60px;
        height: 60px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        position: relative; }
        .viewbideo1 .imgwrp .lnk .playbtn::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid #fff;
          transform: translate(-50%, -50%) scale(1);
          animation: playbtnanim 1s ease infinite; }

@keyframes playbtnanim {
  0% {
    transform: translate(-50%, -50%) scale(0.8); }
  50% {
    opacity: 1; }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0; } }
        .viewbideo1 .imgwrp .lnk .playbtn .ic2-play {
          font-size: 12px;
          color: #000; }

.aboutUs {
  padding-top: 111px;
  position: relative; }
  .aboutUs:before {
    content: '';
    background-image: url(/img/front/svg/wavetopgray.svg);
    background-repeat: no-repeat;
    background-position: center top;
    position: absolute;
    z-index: 1;
    top: -2px;
    bottom: -2px;
    left: 0;
    right: 0; }
  .aboutUs .container {
    position: relative;
    z-index: 2; }

.partners {
  position: relative;
  padding-bottom: 100px; }
  .partners:before {
    content: '';
    position: absolute;
    bottom: -2px;
    right: 0;
    top: -2px;
    left: 0;
    z-index: 1;
    background-image: url("/img/front/svg/wavebotyellow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center bottom; }
  .partners > * {
    position: relative;
    z-index: 2; }
  .partners .partnersimgwrp {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px; }
    .partners .partnersimgwrp a {
      display: flex;
      align-items: center;
      justify-content: center; }
    .partners .partnersimgwrp img {
      max-width: 150px;
      max-height: 92px;
      margin: 1rem;
      object-fit: contain; }

input[type=range] {
  margin-top: 55px;
  -webkit-appearance: none;
  width: 514px;
  display: block; }

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none; }

input[type=range]:focus {
  outline: none; }

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent; }

/* Специальные правила для браузеров на движках WebKit/Blink */
input[type=range]:active::-webkit-slider-thumb {
  border: 1.5px solid #FCD100;
  height: 47px;
  width: 47px;
  margin-top: -23.5px; }

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1.5px solid #c4c4c4;
  height: 37px;
  width: 37px;
  transition: .5s;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -18.5px; }

/* Тоже самое для Firefox */
input[type=range]::-moz-range-thumb {
  border: 1.5px solid #c4c4c4;
  height: 37px;
  width: 37px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer; }

/* Тоже самое для IE */
input[type=range]::-ms-thumb {
  border: 1.5px solid #c4c4c4;
  height: 37px;
  width: 37px;
  border-radius: 50px;
  background: #ffffff; }

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #D4D4D4; }

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #D4D4D4; }

input[type=range]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #D4D4D4; }

input[type=range]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent; }

input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border-radius: 2.6px; }

input[type=range]:focus::-ms-fill-lower {
  background: #D4D4D4; }

input[type=range]::-ms-fill-upper {
  background: #D4D4D4;
  border-radius: 2.6px; }

input[type=range]:focus::-ms-fill-upper {
  background: #367ebd; }

.second input[type=range] {
  width: 100%; }

.btn {
  text-transform: uppercase;
  border: 1px solid rgba(0, 0, 0, 0.1);
  letter-spacing: 0.07em;
  padding: 19px 28px;
  border-radius: 50px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 15px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  transition: .5s;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px; }
  .btn:hover {
    border: 1px solid #FCD100; }
  .btn-y {
    background-color: #FCD100;
    border: 1px solid #FCD100;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: inline-flex;
    justify-content: center;
    align-items: center; }
    .btn-y span {
      font-size: 33px;
      margin-right: 14px; }
  .btn-b {
    background-color: rgba(0, 0, 0, 0.85);
    color: #FCD100; }
    .btn-b:hover {
      background-color: black; }
  .btn-bl {
    background-color: #004266;
    color: #fff; }
  .btn-g {
    background-color: #EAEAEA;
    border: 1px solid #EAEAEA; }
  .btn-w {
    color: #fff;
    border-color: #fff; }

.btn-anim {
  background-color: #FCD100;
  color: #000;
  padding: 20px;
  border-radius: 50px;
  text-decoration: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  margin-left: auto;
  display: block;
  width: 271px;
  text-align: center;
  letter-spacing: 0.02em;
  position: relative;
  border: none; }
  .btn-anim.disabled {
    opacity: 0.5 !important;
    pointer-events: none; }
    .btn-anim.disabled::before {
      display: none !important; }
  .btn-anim::before {
    content: '';
    position: absolute;
    animation: puls 2s infinite;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: -1;
    border-radius: 50px; }
  .btn-anim-footer {
    border: none;
    text-transform: uppercase;
    margin-left: 0; }

.puls {
  position: relative; }
  .puls::before {
    content: '';
    position: absolute;
    animation: puls 2s infinite;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: -1;
    border-radius: 50px; }

.puls2 {
  position: relative; }
  .puls2::before {
    content: '';
    position: absolute;
    animation: puls2 2s infinite;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: -1;
    border-radius: 50px; }

.agreements {
  background: #F6F6F6;
  padding: 60px 0; }
  .agreements .caption {
    margin: 0;
    font-family: Montserrat;
    font-weight: 300;
    font-size: 26px;
    line-height: 32px;
    color: #000000;
    opacity: 0.9; }
    .agreements .caption span {
      font-size: 7px;
      vertical-align: middle;
      margin-left: 10px; }
  .agreements .subcaption {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 160%;
    letter-spacing: 0.005em;
    color: rgba(0, 0, 0, 0.55);
    margin: 15px 0 0; }
  .agreements .agrlnk-wrap {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 767px) {
      .agreements .agrlnk-wrap {
        margin-top: 30px; } }
    .agreements .agrlnk-wrap .ic2-agreements {
      font-size: 50px;
      color: #878098;
      margin-bottom: 25px; }
      @media (max-width: 767px) {
        .agreements .agrlnk-wrap .ic2-agreements {
          margin-top: 30px; } }
    .agreements .agrlnk-wrap .headlnk {
      display: flex;
      align-items: center;
      margin-bottom: 40px; }
      .agreements .agrlnk-wrap .headlnk .imgwrap {
        width: 60px;
        flex: 0 0 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px; }
        .agreements .agrlnk-wrap .headlnk .imgwrap img {
          width: 80px; }
      .agreements .agrlnk-wrap .headlnk > span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 134.77%;
        color: #000000; }
    .agreements .agrlnk-wrap a {
      display: flex;
      align-items: center;
      padding: 12px 15px;
      background: #464646;
      border-radius: 3px;
      max-width: 300px;
      text-decoration: none;
      transition: 0.3s ease; }
      @media (max-width: 995px) {
        .agreements .agrlnk-wrap a {
          padding: 12px 5px; } }
      .agreements .agrlnk-wrap a:hover {
        background: #3E3E3E; }
      .agreements .agrlnk-wrap a + a {
        margin-top: 15px; }
      .agreements .agrlnk-wrap a .ic2-agr-el, .agreements .agrlnk-wrap a .ic2-agr-gas {
        font-size: 41px;
        margin-right: 20px; }
        @media (max-width: 995px) {
          .agreements .agrlnk-wrap a .ic2-agr-el, .agreements .agrlnk-wrap a .ic2-agr-gas {
            margin-right: 5px;
            font-size: 38px; } }
      .agreements .agrlnk-wrap a p {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 13px;
        line-height: 134.77%;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: rgba(242, 242, 242, 0.7);
        margin: 0; }
        @media (max-width: 995px) {
          .agreements .agrlnk-wrap a p {
            font-size: 10px; } }
      .agreements .agrlnk-wrap a.gas p span {
        color: #03A9D2; }
      .agreements .agrlnk-wrap a.el p span {
        color: #FCD100; }
      .agreements .agrlnk-wrap a.comingsoon {
        pointer-events: none;
        background: #ECECEC;
        position: relative;
        padding-top: 25px; }
        .agreements .agrlnk-wrap a.comingsoon * {
          filter: grayscale(100%);
          z-index: 1; }
        .agreements .agrlnk-wrap a.comingsoon p {
          color: #BCBCBC; }
        .agreements .agrlnk-wrap a.comingsoon .coming {
          position: absolute;
          top: 10px;
          right: 10px;
          font-weight: 500;
          font-size: 10px;
          line-height: 100%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: #000000;
          border-radius: 50px;
          background-color: #FCD100;
          padding: 5px 8px;
          filter: grayscale(0%);
          z-index: 2; }

.blogv2 {
  background: #F6F6F6;
  padding: 65px 0 70px; }
  .blogv2 .left .caption {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 30px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.9);
    position: relative;
    padding: 24px 0 0;
    margin: 0; }
    .blogv2 .left .caption:before {
      content: "";
      position: absolute;
      height: 2px;
      width: 40px;
      background-color: #FCD100;
      top: 0;
      left: 0; }
  .blogv2 .left .subcaption {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 160%;
    letter-spacing: 0.005em;
    color: rgba(0, 0, 0, 0.7);
    margin: 20px 0 0; }
  .blogv2 .left .blglnks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 28px 0 25px; }
    .blogv2 .left .blglnks a {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px rgba(31, 14, 67, 0.19);
      border-radius: 11px;
      text-decoration: none;
      margin: 10px 5px;
      padding: 0 10px;
      transition: 0.3s ease; }
      .blogv2 .left .blglnks a:hover {
        box-shadow: 0px 0px 20px rgba(25, 10, 56, 0.19); }
      .blogv2 .left .blglnks a .ic-facebook, .blogv2 .left .blglnks a .ic-telegram, .blogv2 .left .blglnks a .ic2-inst {
        margin: 33px 0;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        font-size: 23px;
        display: flex;
        justify-content: center;
        align-items: center; }
      .blogv2 .left .blglnks a .lnkbtn {
        padding: 12px 27px;
        border-radius: 100px;
        border: 1px solid #000;
        color: #666666;
        margin-bottom: 20px;
        transition: 0.3s ease; }
    .blogv2 .left .blglnks:hover .lnkbtn {
      color: #000; }
    .blogv2 .left .blglnks .fb .ic-facebook {
      background-color: rgba(59, 89, 152, 0.85);
      color: #fff; }
    .blogv2 .left .blglnks .fb .lnkbtn {
      border-color: #3B5998; }
    .blogv2 .left .blglnks .telega .ic-telegram {
      background-color: rgba(55, 174, 226, 0.85);
      color: #fff; }
    .blogv2 .left .blglnks .telega .lnkbtn {
      border-color: #37AEE2; }
    .blogv2 .left .blglnks .insta .ic2-inst {
      background: radial-gradient(47.51% 47.51% at 28.56% 100%, #FDDB86 1%, rgba(240, 105, 66, 0) 100%), radial-gradient(74.61% 74.61% at 22.02% 100%, #FFAD05 13%, rgba(255, 63, 0, 0) 100%), linear-gradient(159.86deg, #4263DF 5.92%, rgba(213, 53, 133, 0) 77.32%), #E11B7E;
      color: #fff; }
    .blogv2 .left .blglnks .insta .lnkbtn {
      border-color: #f06942; }
  .blogv2 .left .blogmore {
    padding: 15px 0px;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 134.77%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    display: block;
    text-decoration: none;
    border-radius: 100px;
    border: 1px solid #D4D4D4;
    transition: 0.3s ease; }
    .blogv2 .left .blogmore:hover {
      border-color: #FCD619; }
    .blogv2 .left .blogmore .ic-arrow {
      font-size: 6px;
      vertical-align: middle;
      margin-left: 10px; }
  .blogv2 .news-wrap {
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(31, 14, 67, 0.19);
    border-radius: 4px;
    padding: 16px 22px 18px;
    transition: 0.3s ease; }
    @media (max-width: 767px) {
      .blogv2 .news-wrap {
        margin-top: 30px; } }
    .blogv2 .news-wrap:hover {
      box-shadow: 0px 0px 20px rgba(25, 10, 56, 0.19); }
    .blogv2 .news-wrap img {
      pointer-events: auto !important;
      width: 100%; }
    .blogv2 .news-wrap .captionlnk, .blogv2 .news-wrap .captionlnk * {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: #000000;
      opacity: 0.9;
      margin: 0;
      text-decoration: none; }
    .blogv2 .news-wrap .captionlnk {
      display: block;
      margin: 0 20px 0; }
    .blogv2 .news-wrap .tags {
      margin: 35px 20px 0; }
      .blogv2 .news-wrap .tags a {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140.62%;
        text-decoration-line: underline;
        color: #121212;
        opacity: 0.5; }
        .blogv2 .news-wrap .tags a + a {
          margin-left: 10px; }

.chooseUs {
  position: relative;
  padding: 30px 0 0; }
  .chooseUs.section {
    padding: 30px 40px; }
  .chooseUs.section:after {
    display: none !important; }
  .chooseUs:after {
    content: "";
    position: absolute;
    bottom: 0;
    top: -1px;
    left: 0px;
    right: 0px;
    z-index: 2;
    background-image: url(/img/front/svg/wavetopgray.svg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain; }
  .chooseUs > * {
    position: relative;
    z-index: 5; }
  .chooseUs-l {
    top: 0;
    position: sticky; }
    .chooseUs-l img {
      width: calc(100% - 50px);
      margin-left: 50px; }
  .chooseUs-r {
    padding-top: 120px;
    padding-left: 125px;
    width: 60%;
    padding-bottom: 125px;
    overflow-y: unset; }
    .chooseUs-r::-webkit-scrollbar {
      width: 3px; }
    .chooseUs-r::-webkit-scrollbar-track-piece {
      background-color: transparent; }
    .chooseUs-r::-webkit-scrollbar-thumb {
      background-color: #aaa;
      border-radius: 3px; }
    .chooseUs-r__ul li {
      overflow: hidden;
      position: relative;
      margin-top: 30px;
      padding-left: 95px; }
      .chooseUs-r__ul li [class^="ic2-"], .chooseUs-r__ul li [class*=" ic2-"] {
        position: absolute;
        top: 24px;
        left: 0;
        font-size: 55px;
        margin-left: 20px;
        color: rgba(25, 10, 56, 0.52); }
      .chooseUs-r__ul li .ic2-ic-2 {
        font-size: 44px; }
      .chooseUs-r__ul li .ic2-ic-3 {
        font-size: 38px; }
      .chooseUs-r__ul li .ic2-ic-4 {
        font-size: 43px; }
      @media (max-width: 550px) {
        .chooseUs-r__ul li a {
          margin-left: -80px; } }
    .chooseUs-r__ul .name {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.9); }
    .chooseUs-r__ul .description {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 160%;
      letter-spacing: 0.05em;
      color: rgba(0, 0, 0, 0.55);
      margin-bottom: 0; }
    .chooseUs-r__ul .sticker {
      background-color: #FCD100;
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 40px;
      transform: skew(45deg); }

.tarifsec {
  overflow: hidden; }
  .tarifsec .calctabs {
    display: flex;
    margin-top: 45px; }
    .tarifsec .calctabs div {
      cursor: pointer;
      width: 380px;
      padding: 28px 0;
      text-align: center;
      border-radius: 0px 12px 0px 0px;
      position: relative;
      z-index: 1; }
      .tarifsec .calctabs div .ic2-bussiness-source,
      .tarifsec .calctabs div .ic2-for-home-source {
        margin-right: 20px;
        transition: 0.3s ease; }
      .tarifsec .calctabs div.active {
        box-shadow: inset 0 0 0 3px #FCD100;
        z-index: 2; }
        .tarifsec .calctabs div.active .ic2-bussiness-source,
        .tarifsec .calctabs div.active .ic2-for-home-source {
          color: #FCD100; }
    .tarifsec .calctabs .busines {
      background-color: #5B5A5A;
      position: relative; }
      .tarifsec .calctabs .busines:before {
        content: '';
        position: absolute;
        background-color: #5B5A5A;
        height: 100%;
        width: 50vw;
        top: 0;
        left: 0;
        transform: translate(-100%, 0); }
      .tarifsec .calctabs .busines span {
        font-size: 20px;
        line-height: 134.77%;
        letter-spacing: -0.015em;
        color: rgba(255, 255, 255, 0.64); }
    .tarifsec .calctabs .people {
      background-color: #F2F2F2;
      margin-left: -12px;
      border-radius: 12px 12px 0px 0px; }
      .tarifsec .calctabs .people .ic2-for-home-source {
        color: #B5B5B5; }
      .tarifsec .calctabs .people span {
        font-size: 20px;
        line-height: 134.77%;
        letter-spacing: -0.015em;
        color: #000000; }
  .tarifsec .calcwrp {
    position: relative;
    background-position: center top;
    background-size: cover;
    padding: 68px 0 142px; }
    .tarifsec .calcwrp:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, #5B5A5A 0%, rgba(48, 48, 48, 0.76) 100%);
      z-index: 1;
      transition: 0.3s ease; }
    .tarifsec .calcwrp:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: -1px;
      left: -6px;
      right: -6px;
      z-index: 2;
      background-image: url(/img/front/svg/wavebotgray.svg);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: contain; }
    .tarifsec .calcwrp.noafter:after {
      display: none !important; }
    .tarifsec .calcwrp.businesmode:before {
      opacity: 1; }
    .tarifsec .calcwrp.peoplemode:before {
      opacity: 0; }
    .tarifsec .calcwrp .hd {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 767px) {
        .tarifsec .calcwrp .hd {
          flex-direction: column; } }
      .tarifsec .calcwrp .hd > p {
        font-size: 26px;
        line-height: 140%;
        color: #1A1A1A;
        margin: 0; }
        @media (max-width: 767px) {
          .tarifsec .calcwrp .hd > p {
            font-size: 22px; } }
      .tarifsec .calcwrp .hd .tumblerwrp {
        margin-top: 30px;
        align-self: flex-start; }
        .tarifsec .calcwrp .hd .tumblerwrp .tumbler {
          display: flex;
          position: relative;
          border-radius: 50px;
          background-color: #F6F6F6; }
          .tarifsec .calcwrp .hd .tumblerwrp .tumbler .comingsoon {
            position: absolute;
            top: -5px;
            right: 0;
            border-radius: 50px;
            background: #FCD100;
            font-weight: 500;
            font-size: 10px;
            line-height: 134.77%;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: #000000;
            padding: 3px 11px;
            transform: translate(0, -100%); }
          .tarifsec .calcwrp .hd .tumblerwrp .tumbler > div {
            font-weight: 500;
            font-size: 13px;
            line-height: 134.77%;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: #7C7C7C;
            padding: 10px 20px;
            border-radius: 50px;
            display: flex;
            align-items: center; }
            @media (max-width: 767px) {
              .tarifsec .calcwrp .hd .tumblerwrp .tumbler > div {
                padding: 10px 10px;
                font-size: 10px; } }
            .tarifsec .calcwrp .hd .tumblerwrp .tumbler > div .ic-gas, .tarifsec .calcwrp .hd .tumblerwrp .tumbler > div .ic-electro {
              font-size: 22px;
              margin-right: 10px; }
            .tarifsec .calcwrp .hd .tumblerwrp .tumbler > div.active {
              background: #FCD100;
              color: #000; }
    .tarifsec .calcwrp .businescalcbody {
      position: relative;
      z-index: 3;
      display: flex; }
      @media (max-width: 991px) {
        .tarifsec .calcwrp .businescalcbody {
          flex-direction: column; } }
      .tarifsec .calcwrp .businescalcbody .left {
        background: #FFFFFF;
        border-radius: 8px 0px 0px 8px;
        padding: 32px 50px 50px;
        width: 65%; }
        @media (max-width: 991px) {
          .tarifsec .calcwrp .businescalcbody .left {
            width: unset;
            border-radius: 8px 8px 0px 0px;
            padding: 23px 17px 30px; } }
        .tarifsec .calcwrp .businescalcbody .left .inptext {
          font-size: 20px;
          line-height: 134.77%;
          color: #1A1A1A;
          margin: 48px 0 0; }
        .tarifsec .calcwrp .businescalcbody .left .inputwrp {
          border-radius: 50px;
          margin: 16px 0 0;
          border: 3px solid #FCD100;
          position: relative; }
          .tarifsec .calcwrp .businescalcbody .left .inputwrp:after {
            content: "м³";
            top: 50%;
            right: 34px;
            position: absolute;
            transform: translate(0, -50%);
            font-weight: 500;
            font-size: 16px;
            line-height: 134.77%;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: #000000;
            opacity: 0.5; }
          .tarifsec .calcwrp .businescalcbody .left .inputwrp input {
            background-color: transparent;
            border: none;
            width: 100%;
            height: 61px;
            padding: 0 68px 0 16px;
            font-size: 18px;
            line-height: 134.77%;
            color: #000000; }
        .tarifsec .calcwrp .businescalcbody .left input[type="range"] {
          margin-top: 48px;
          width: 100%; }
        .tarifsec .calcwrp .businescalcbody .left .calcundertxt {
          font-size: 16px;
          line-height: 140%;
          color: #4D4D4D;
          margin: 65px 0 0; }
      .tarifsec .calcwrp .businescalcbody .right {
        background: #E5E5E5;
        border-radius: 0px 8px 8px 0px;
        width: 35%;
        padding: 30px 35px 30px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        @media (max-width: 991px) {
          .tarifsec .calcwrp .businescalcbody .right {
            width: unset;
            border-radius: 0px 0px 8px 8px; } }
        .tarifsec .calcwrp .businescalcbody .right > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: #3A3A3A solid 2px;
          padding-bottom: 8px; }
          .tarifsec .calcwrp .businescalcbody .right > div + div {
            margin-top: 1rem; }
          .tarifsec .calcwrp .businescalcbody .right > div .txt {
            font-size: 15px;
            line-height: 134.77%;
            color: #000000;
            margin: 0; }
          .tarifsec .calcwrp .businescalcbody .right > div .val {
            font-weight: 500;
            font-size: 26px;
            line-height: 134.77%;
            color: #000000;
            margin: 0; }
    .tarifsec .calcwrp .peopletarifs {
      position: relative;
      z-index: 3;
      background: #FFFFFF;
      box-shadow: 0px 8px 11px rgba(21, 9, 49, 0.08);
      border-radius: 8px; }
      .tarifsec .calcwrp .peopletarifs .hd {
        padding: 38px 30px 32px 48px; }
        .tarifsec .calcwrp .peopletarifs .hd > p {
          max-width: 600px; }
      .tarifsec .calcwrp .peopletarifs .blckbody {
        padding: 32px 48px 46px;
        display: flex;
        justify-content: space-between; }
        @media (max-width: 767px) {
          .tarifsec .calcwrp .peopletarifs .blckbody {
            flex-direction: column; } }
        .tarifsec .calcwrp .peopletarifs .blckbody .maininfo {
          flex: 0 0 42%;
          padding: 0 105px 0 0; }
          @media (max-width: 767px) {
            .tarifsec .calcwrp .peopletarifs .blckbody .maininfo {
              flex: 0 0 100%;
              padding: 0; } }
          .tarifsec .calcwrp .peopletarifs .blckbody .maininfo .title {
            font-weight: 500;
            font-size: 30px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.9);
            margin: 0 0 37px; }
          .tarifsec .calcwrp .peopletarifs .blckbody .maininfo .tarifprops div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 8px;
            border-bottom: #000 solid 2px; }
            .tarifsec .calcwrp .peopletarifs .blckbody .maininfo .tarifprops div + div {
              margin-top: 14px; }
            .tarifsec .calcwrp .peopletarifs .blckbody .maininfo .tarifprops div .txt {
              font-size: 14px;
              line-height: 160%;
              letter-spacing: 0.02em;
              color: #000000;
              margin: 0; }
            .tarifsec .calcwrp .peopletarifs .blckbody .maininfo .tarifprops div .val {
              font-weight: 500;
              font-size: 18px;
              line-height: 160%;
              letter-spacing: 0.02em;
              color: #000000;
              margin: 0; }
              .tarifsec .calcwrp .peopletarifs .blckbody .maininfo .tarifprops div .val b {
                font-size: 24px; }
        @media (max-width: 988px) {
          .tarifsec .calcwrp .peopletarifs .blckbody .cons {
            margin-top: 30px; } }
        .tarifsec .calcwrp .peopletarifs .blckbody .cons > div + div {
          margin-top: 16px; }
        .tarifsec .calcwrp .peopletarifs .blckbody .cons > div .title {
          font-weight: 500;
          font-size: 18px;
          line-height: 160%;
          color: #000000;
          margin: 0; }
        .tarifsec .calcwrp .peopletarifs .blckbody .cons > div .txt {
          font-size: 16px;
          line-height: 160%;
          color: #3C3C3C;
          margin: 3px 0 0; }
        .tarifsec .calcwrp .peopletarifs .blckbody .cons .lnk {
          display: flex;
          justify-content: flex-end; }
          .tarifsec .calcwrp .peopletarifs .blckbody .cons .lnk a {
            padding: 16px 24px;
            background: #5B5A5A;
            border-radius: 50px;
            display: flex;
            text-decoration: none;
            align-items: center;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: #FFFFFF; }
            .tarifsec .calcwrp .peopletarifs .blckbody .cons .lnk a .ic-arrow {
              font-size: 6px;
              margin-left: 16px; }

.full-width-block {
  width: 100%;
  background-color: #FCD100;
  padding-top: 25px;
  padding-bottom: 25px; }
  .full-width-block .container {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .full-width-block .container .btn {
      margin-top: 0; }

.created_by {
  background: #FAFAFA;
  text-align: center; }

.tarif-main {
  display: block;
  background: #FAFAFA; }
  .tarif-main .content {
    max-width: 1110px;
    margin: auto; }
    @media (max-width: 1250px) {
      .tarif-main .content {
        padding-left: 20px;
        padding-right: 20px; } }
    .tarif-main .content .calc {
      padding-top: 20px;
      padding-bottom: 20px; }
      .tarif-main .content .calc .calculator-lr {
        box-shadow: 0px 0px 20px rgba(142, 156, 170, 0.25);
        border-radius: 8px;
        overflow: hidden; }
    .tarif-main .content .page-tabs {
      width: 100%;
      display: flex;
      position: relative;
      z-index: 2;
      user-select: none; }
      .tarif-main .content .page-tabs .tb {
        width: 315px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 11px 12px 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 134.77%;
        color: #000000; }
        .tarif-main .content .page-tabs .tb .imgwrap {
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #fff;
          margin-right: 20px; }
          .tarif-main .content .page-tabs .tb .imgwrap img {
            width: 37px; }
        @media (max-width: 490px) {
          .tarif-main .content .page-tabs .tb {
            flex-grow: 1;
            width: unset;
            justify-content: center; }
            .tarif-main .content .page-tabs .tb span {
              display: none; } }
      .tarif-main .content .page-tabs .tb.active {
        box-shadow: 0 2px 0  #FCD100; }
        .tarif-main .content .page-tabs .tb.active span {
          display: block !important; }
    .tarif-main .content .heading h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.9);
      margin: 0;
      padding-top: 35px;
      position: relative; }
    .tarif-main .content .heading p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.9);
      mix-blend-mode: normal;
      margin: 25px 0 20px; }
    .tarif-main .content .bottext {
      margin-top: 30px;
      color: rgba(0, 0, 0, 0.9);
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 135%;
      margin-bottom: 26px;
      text-align: justify; }

.tariftype-switcher-wrap {
  display: flex;
  justify-content: flex-end; }
  @media (max-width: 890px) {
    .tariftype-switcher-wrap {
      justify-content: center; } }
  .tariftype-switcher-wrap .tariftype-switcher {
    display: flex;
    background: #F6F6F6;
    border-radius: 50px;
    box-shadow: inset 0 0 0 1px #EDEDED; }
    @media (max-width: 540px) {
      .tariftype-switcher-wrap .tariftype-switcher {
        width: 100%; } }
    .tariftype-switcher-wrap .tariftype-switcher.elcs {
      margin-top: 30px;
      position: relative; }
      .tariftype-switcher-wrap .tariftype-switcher.elcs:after {
        content: 'скоро буде';
        position: absolute;
        top: -30px;
        right: 0;
        padding: 5px 10px;
        border-radius: 50px;
        background-color: #FCD100;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 134.77%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #000000; }
    .tariftype-switcher-wrap .tariftype-switcher button {
      min-width: 225px;
      border-radius: 50px;
      min-height: 42px;
      border: none;
      background: none;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 134.77%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.5); }
      @media (max-width: 540px) {
        .tariftype-switcher-wrap .tariftype-switcher button {
          width: 50%;
          min-width: unset;
          font-size: 10px; } }
      .tariftype-switcher-wrap .tariftype-switcher button .ic-electro, .tariftype-switcher-wrap .tariftype-switcher button .ic-gas {
        vertical-align: middle;
        font-size: 22px;
        margin-right: 15px; }
        @media (max-width: 540px) {
          .tariftype-switcher-wrap .tariftype-switcher button .ic-electro, .tariftype-switcher-wrap .tariftype-switcher button .ic-gas {
            margin-right: 8px; } }
    .tariftype-switcher-wrap .tariftype-switcher button.gas.active {
      background: #004266;
      color: #fff; }
    .tariftype-switcher-wrap .tariftype-switcher button.electro.active {
      background: #FCD100;
      color: #000; }

.tarifs-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media (max-width: 890px) {
    .tarifs-block {
      justify-content: space-around; } }
  .tarifs-block .tarif-item, .tarifs-block .tarifplaceholder {
    margin-top: 40px;
    flex: 0 0 calc(33% - 15px); }
    @media (max-width: 890px) {
      .tarifs-block .tarif-item, .tarifs-block .tarifplaceholder {
        flex: 0 0 calc(40% - 15px); } }
    @media (max-width: 540px) {
      .tarifs-block .tarif-item, .tarifs-block .tarifplaceholder {
        flex: 0 0 calc(100% - 40px); } }
  .tarifs-block .tarif-item {
    display: flex;
    flex-direction: column;
    transition: 0.3s ease; }
    .tarifs-block .tarif-item:hover {
      box-shadow: 0px 4px 20px rgba(25, 10, 56, 0.19); }
    .tarifs-block .tarif-item .termin {
      display: flex;
      justify-content: space-between;
      padding: 7px 25px; }
      .tarifs-block .tarif-item .termin span:nth-child(1) {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 160%;
        letter-spacing: 0.02em;
        color: #FFFFFF; }
      .tarifs-block .tarif-item .termin span:nth-child(2) {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 160%;
        text-align: right;
        color: #FFFFFF; }
    .tarifs-block .tarif-item .name {
      height: 115px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid rgba(25, 10, 56, 0.05);
      border-right: 1px solid rgba(25, 10, 56, 0.05);
      background-color: #fff; }
      .tarifs-block .tarif-item .name span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 150%;
        text-align: center;
        color: rgba(0, 0, 0, 0.9); }
    .tarifs-block .tarif-item .prices .head {
      background: #E9E9E9;
      display: flex;
      justify-content: space-between;
      padding: 7px 25px; }
      .tarifs-block .tarif-item .prices .head span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 160%;
        letter-spacing: 0.02em;
        color: #000000; }
    .tarifs-block .tarif-item .prices .item {
      min-height: 60px;
      background: #F4F4F4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 22px; }
      .tarifs-block .tarif-item .prices .item + .item {
        border-top: 1px solid rgba(0, 0, 0, 0.05); }
      .tarifs-block .tarif-item .prices .item .pricewrap .p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 160%;
        letter-spacing: 0.02em;
        color: #000000; }
      .tarifs-block .tarif-item .prices .item .pricewrap .curency {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.02em;
        color: #000000; }
      .tarifs-block .tarif-item .prices .item .mouth {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.02em;
        color: #000000; }
    .tarifs-block .tarif-item .tarif-expander {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      background-color: #fff;
      border-left: 1px solid rgba(25, 10, 56, 0.05);
      border-right: 1px solid rgba(25, 10, 56, 0.05);
      border-bottom: 1px solid rgba(25, 10, 56, 0.05);
      padding-bottom: 45px; }
    .tarifs-block .tarif-item .tarif-advantages {
      margin: 0; }
      .tarifs-block .tarif-item .tarif-advantages li {
        padding: 25px 15px 0 60px;
        position: relative; }
        .tarifs-block .tarif-item .tarif-advantages li:before {
          content: "\e926";
          font-family: 'megawatt2' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
          top: 25px;
          left: 25px; }
        .tarifs-block .tarif-item .tarif-advantages li .h {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 160%;
          color: #000000;
          margin: 0;
          text-align: left; }
        .tarifs-block .tarif-item .tarif-advantages li .t {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 160%;
          color: rgba(0, 0, 0, 0.55);
          margin: 5px 0 0;
          text-align: left; }
    .tarifs-block .tarif-item .lnk {
      padding: 16px 0;
      border-radius: 50px;
      border: 1px solid #000;
      align-self: flex-start;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 134.77%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #000000;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 220px;
      margin-left: 25px;
      margin-top: 35px;
      transition: 0.3s ease; }
      .tarifs-block .tarif-item .lnk .ic-arrow {
        transition: 0.3s ease;
        font-size: 7px;
        margin-left: 25px;
        color: rgba(0, 0, 0, 0.5); }
  .tarifs-block .tarifplaceholder {
    background: #F4F4F4;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px; }
    .tarifs-block .tarifplaceholder .cs {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 5px 10px;
      border-radius: 50px;
      color: #000000;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 134.77%;
      letter-spacing: 0.07em;
      text-transform: uppercase; }
    .tarifs-block .tarifplaceholder .t {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 150%;
      text-align: center;
      color: rgba(0, 0, 0, 0.2); }

.gas .heading h1:before {
  background: #004266; }

.gas .tarifs-block .tarif-item .termin {
  background-color: #004266; }

.gas .tarifs-block .tarif-item .lnk {
  border-color: #004266; }
  .gas .tarifs-block .tarif-item .lnk:hover {
    background: #004266;
    color: #fff; }
    .gas .tarifs-block .tarif-item .lnk:hover .ic-arrow {
      color: #fff; }

.gas .tarifs-block .tarifplaceholder .cs {
  background: #004266;
  color: #fff; }

.electro .heading h1:before {
  background: #FCD100; }

.electro .tarifs-block .tarif-item .termin {
  background-color: #FCD100; }
  .electro .tarifs-block .tarif-item .termin span {
    color: #000; }

.electro .tarifs-block .tarif-item .lnk {
  border-color: #FCD100; }
  .electro .tarifs-block .tarif-item .lnk:hover {
    background: #FCD100;
    color: #000; }
    .electro .tarifs-block .tarif-item .lnk:hover .ic-arrow {
      color: #000; }

.electro .tarifs-block .tarifplaceholder .cs {
  background: #FCD100;
  color: #000; }

.footer {
  padding: 120px 0;
  position: relative; }
  .footer:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    background-image: url("/img/front/svg/wavetopgray.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top; }
  .footer .description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: 0.05em; }
  .footer-r {
    text-align: right; }
    .footer-r .footer-logo img {
      width: 198px;
      height: 40px;
      margin-bottom: 60px; }
    .footer-r__ul li {
      margin-bottom: 35px; }
    .footer-r__ul .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 140%;
      letter-spacing: 0.073m;
      color: rgba(0, 0, 0, 0.55);
      text-transform: uppercase;
      position: relative;
      margin-bottom: 22px; }
      .footer-r__ul .title::before {
        position: absolute;
        content: '';
        bottom: -11px;
        right: 0;
        width: 43px;
        height: 2px;
        background-color: #c4c4c4; }
    .footer-r__ul .subtitle {
      margin-left: auto;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 160%;
      letter-spacing: 0.03em;
      color: rgba(0, 0, 0, 0.9);
      max-width: 270px;
      margin-bottom: 2px;
      margin-top: 2px; }
      .footer-r__ul .subtitle * {
        color: rgba(0, 0, 0, 0.9) !important; }
    .footer-r__ul .social {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-end; }
      .footer-r__ul .social a {
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: rgba(0, 0, 0, 0.9);
        width: 40px;
        height: 40px;
        transition: .5s;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        border-radius: 50%; }
        .footer-r__ul .social a:not(:last-of-type) {
          margin-right: 25px; }
        .footer-r__ul .social a:hover {
          border: 1px solid rgba(0, 0, 0, 0.7);
          color: rgba(0, 0, 0, 0.7); }

.created_by {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding: 10px 0;
  opacity: 1; }
  .created_by .container {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .created_by .container {
        flex-direction: column; } }
  .created_by .payerLabels {
    display: flex;
    justify-content: center; }
    .created_by .payerLabels .item {
      padding: 5px 11px;
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 4.31338px;
      display: flex;
      align-items: center; }
      .created_by .payerLabels .item + .item {
        margin-left: 5px; }
  .created_by .created_by_txt {
    margin-top: 10px;
    opacity: 0.5; }
  .created_by a {
    color: #000;
    text-decoration: none; }

#footer-form {
  margin-top: 27px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  #footer-form .input-wrap {
    position: relative;
    width: 50%;
    margin-bottom: 16px; }
    #footer-form .input-wrap input {
      border-radius: 4px 4px 0px 0px;
      background-color: #fafafa;
      height: 46px;
      padding: 0px 0 0 40px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      border: none;
      border-bottom: 1.5px solid #e5e5e5;
      width: calc(100% - 14px);
      transition: .5s; }
      #footer-form .input-wrap input:focus {
        border-bottom: 2px solid #FCD100; }
    #footer-form .input-wrap label.error {
      position: absolute;
      bottom: 0px;
      left: 40px;
      color: rgba(255, 0, 0, 0.8);
      font-family: Montserrat;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: -0.03em; }
    #footer-form .input-wrap span {
      color: #aaa;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      font-size: 20px; }
    #footer-form .input-wrap .ic-mail {
      font-size: 16px; }
  #footer-form textarea {
    border-radius: 4px 4px 0px 0px;
    background-color: #fafafa;
    height: 46px;
    padding: 10px 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    border: none;
    border-bottom: 1.5px solid #e5e5e5 !important;
    width: calc(100% - 14px);
    transition: .5s;
    resize: none;
    height: 109px;
    margin-bottom: 22px; }
    #footer-form textarea:focus {
      border-bottom: 2px solid #FCD100; }

.overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  display: none;
  background-color: rgba(0, 0, 0, 0.3); }

.menu {
  position: fixed;
  left: 0;
  transition: .5s;
  top: 0;
  background-color: #fff;
  z-index: 1000;
  height: 100%;
  padding-top: 24px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%); }
  .menu-social a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .menu-social a:hover span {
      color: #000; }
    .menu-social a span {
      color: rgba(0, 0, 0, 0.5); }
  .menu .ic {
    font-size: 22px;
    width: 35px; }
  .menu .ic.ic2-about {
    font-size: 12px; }
  .menu .close-modal {
    width: 47px;
    height: 47px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D4D4D4;
    border-radius: 50%;
    text-decoration: none;
    font-size: 35px;
    color: #000;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500; }
  .menu-head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    padding-bottom: 30px;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0.03); }
  .menu-head, .menu-body, .menu-footer {
    padding-right: 24px;
    padding-left: 24px; }
  .menu-body {
    flex-grow: 1;
    overflow-y: auto; }
    .menu-body .log-reg {
      text-transform: uppercase;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      margin-bottom: 20px; }
      .menu-body .log-reg a {
        text-decoration: none;
        border-radius: 50px;
        padding: 17px 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.8); }
      .menu-body .log-reg .log {
        padding: 17px 25px; }
    .menu-body .menu__ul {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between; }
      .menu-body .menu__ul li {
        width: 245px;
        margin-bottom: 4px;
        margin-top: 4px; }
        .menu-body .menu__ul li.group {
          box-shadow: inset 0 0 0 3px rgba(252, 209, 0, 0.76);
          border-radius: 25px;
          padding-bottom: 8px; }
          .menu-body .menu__ul li.group .title {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.7);
            margin: 19px 25px 15px;
            display: block; }
          .menu-body .menu__ul li.group a {
            width: calc(100% - 15px);
            margin: 0 auto; }
            .menu-body .menu__ul li.group a + a {
              margin-top: 8px; }
            .menu-body .menu__ul li.group a .ic {
              width: 27px;
              font-size: 18px; }
        .menu-body .menu__ul li a {
          border-radius: 50px;
          background: rgba(0, 0, 0, 0.07);
          padding: 19px 26px;
          width: 100%;
          display: block;
          text-transform: uppercase;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.7);
          text-decoration: none;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          transition: .5s; }
          .menu-body .menu__ul li a:hover {
            background-color: rgba(0, 0, 0, 0.09);
            color: #000; }
  .menu-footer {
    flex-grow: 0; }
    .menu-footer .menu-social {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between; }
      .menu-footer .menu-social li {
        width: 36px;
        height: 36px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.07); }
        .menu-footer .menu-social li a {
          text-decoration: none; }

.bottom-m {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 250px;
  height: 50px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(142, 156, 170, 0.25);
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  z-index: 100;
  text-decoration: none; }
  .bottom-m span {
    color: #FCD100;
    margin-right: 10px; }

.r-col .l {
  position: relative; }

.modal-video {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  text-align: center; }
  .modal-video span {
    color: #fff;
    text-decoration: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 135%; }
  .modal-video::before {
    color: #fff;
    position: absolute;
    content: '▶';
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 20px;
    left: 50%;
    transform: translateX(-50%);
    top: 43%; }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
  display: none; }
  .lightbox .video-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.close-modal {
  position: absolute;
  right: 20px;
  top: 20px;
  text-decoration: none;
  color: #fff;
  font-size: 30px;
  width: 51px;
  height: 51px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center; }

.modal {
  z-index: 200; }

.modal-vid {
  position: fixed;
  display: none; }
  .modal-vid .close-modal {
    position: fixed; }

.modal-vid iframe {
  width: 68vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media (max-width: 768px) {
    .modal-vid iframe {
      width: 100vw; } }

.cust-overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)); }

@media (max-width: 576px) {
  .menu {
    overflow-y: scroll; } }

@keyframes ajaxmodaloverlayapear {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ajaxmodalapear {
  from {
    transform: translate(0, -100px);
    opacity: 0; }
  to {
    transform: translate(0, 0);
    opacity: 1; } }

.modal-overlay-contformajax {
  animation: ajaxmodaloverlayapear 0.2s ease;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .modal-overlay-contformajax .modal-body {
    animation: ajaxmodalapear 1s ease;
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(25, 10, 56, 0.03);
    box-sizing: border-box;
    box-shadow: 0px 5px 7px rgba(25, 10, 56, 0.19), 0px 0px 15px rgba(25, 10, 56, 0.05);
    max-width: 538px;
    position: relative; }
    .modal-overlay-contformajax .modal-body .close {
      position: absolute;
      width: 31px;
      height: 31px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.15);
      top: 13px;
      right: 15px;
      cursor: pointer; }
      .modal-overlay-contformajax .modal-body .close:before, .modal-overlay-contformajax .modal-body .close:after {
        content: "";
        width: 15px;
        height: 2px;
        background-color: #000;
        position: absolute;
        top: 50%;
        left: 50%; }
      .modal-overlay-contformajax .modal-body .close:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      .modal-overlay-contformajax .modal-body .close:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
    .modal-overlay-contformajax .modal-body > div:nth-child(1) {
      padding: 50px 0 50px; }
      .modal-overlay-contformajax .modal-body > div:nth-child(1) .ic-round-check {
        font-size: 60px;
        color: #fff;
        position: relative; }
        .modal-overlay-contformajax .modal-body > div:nth-child(1) .ic-round-check:before {
          position: relative;
          z-index: 2; }
        .modal-overlay-contformajax .modal-body > div:nth-child(1) .ic-round-check:after {
          content: "";
          position: absolute;
          transform: scale(1.1);
          background-color: #FCD100;
          border-radius: 50%;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1; }
      .modal-overlay-contformajax .modal-body > div:nth-child(1) .m-title {
        font-family: Montserrat;
        font-size: 20px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.9);
        margin: 34px 0 0;
        padding: 0 0 15px;
        position: relative; }
        .modal-overlay-contformajax .modal-body > div:nth-child(1) .m-title:after {
          content: "";
          position: absolute;
          height: 2px;
          width: 43px;
          background-color: #FCD100;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 0); }
    .modal-overlay-contformajax .modal-body .m-text {
      font-family: Montserrat;
      font-size: 16px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.65);
      margin: 20px 0 0;
      padding: 0 50px; }
      .modal-overlay-contformajax .modal-body .m-text + .m-text {
        margin-top: 8px; }
      .modal-overlay-contformajax .modal-body .m-text .accent {
        color: black; }
    .modal-overlay-contformajax .modal-body > div:nth-child(2) {
      background-color: #FAFAFA;
      padding: 20px 0; }
      .modal-overlay-contformajax .modal-body > div:nth-child(2) .m-text {
        margin: 0; }
        .modal-overlay-contformajax .modal-body > div:nth-child(2) .m-text + .m-text {
          margin-top: 8px; }

.show-left {
  transform: translateX(0%); }

@keyframes puls {
  0% {
    transform: scale(1, 1);
    background-color: rgba(252, 209, 0, 0.3); }
  50% {
    transform: scale(1.09, 1.3);
    background-color: rgba(252, 209, 0, 0.4); }
  100% {
    transform: scale(1, 1);
    background-color: rgba(252, 209, 0, 0.3); } }

@keyframes puls2 {
  0% {
    transform: scale(1, 1);
    background-color: rgba(0, 66, 102, 0.3); }
  50% {
    transform: scale(1.09, 1.3);
    background-color: rgba(0, 66, 102, 0.4); }
  100% {
    transform: scale(1, 1);
    background-color: rgba(0, 66, 102, 0.3); } }

@keyframes svg_f2 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes svg_f3 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-360deg); } }

@keyframes svg_f5 {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

#f2 {
  animation: svg_f2 20s infinite linear; }

#f3 {
  animation: svg_f3 20s infinite linear; }

#f5 {
  animation: svg_f5 10s infinite linear; }

#f3, #f2, #f1, #f4, #f5, #a1, #a2 {
  transform-origin: 50%; }

.i {
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  margin-left: 17px; }

.section-h1 {
  font-size: 48px;
  color: rgba(0, 0, 0, 0.9);
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  line-height: 135%; }

.section-h2 {
  color: rgba(0, 0, 0, 0.9);
  font-size: 26px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  line-height: 135%; }

.section-h4 {
  color: rgba(0, 0, 0, 0.55);
  font-size: 14px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 0.04em;
  position: relative; }
  .section-h4::before {
    content: '';
    position: absolute;
    top: 32px;
    left: 0;
    background-color: #FCD100;
    height: 2px;
    width: 43px; }

.section-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.9);
  font-size: 34px;
  line-height: 110%; }

.section-ul li {
  position: relative;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.55);
  padding-left: 20px; }
  .section-ul li:before {
    position: absolute;
    content: '⬤';
    top: 0;
    left: 0;
    font-size: 8px;
    color: #000; }

.description {
  letter-spacing: 0.02em;
  line-height: 28px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.55); }
  .description a {
    text-decoration: none;
    color: #03A9D2; }

@media (max-width: 425px) {
  .section-title {
    font-size: 29px;
    margin-top: 18px; }
  .section-h4 {
    margin-bottom: 0;
    margin-top: 0; }
    .section-h4::before {
      top: 40px; }
    .section-h4 ~ a {
      white-space: nowrap; } }

@font-face {
  font-family: 'megawatt';
  src: url("../icofonts/megawatt.eot?xg31qi");
  src: url("../icofonts/megawatt.eot?xg31qi#iefix") format("embedded-opentype"), url("../icofonts/megawatt.ttf?xg31qi") format("truetype"), url("../icofonts/megawatt.woff?xg31qi") format("woff"), url("../icofonts/megawatt.svg?xg31qi#megawatt") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="ic-"], [class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'megawatt' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ic-f-arrow-up:before {
  content: "\e937"; }

.ic-f-arrow-down:before {
  content: "\e938"; }

.ic-menu-dots:before {
  content: "\e936"; }

.ic-uah:before {
  content: "\e934"; }

.ic-calendar:before {
  content: "\e935"; }

.ic-check:before {
  content: "\e930"; }

.ic-cloud:before {
  content: "\e931"; }

.ic-gerb:before {
  content: "\e932"; }

.ic-arrowleft-figure:before {
  content: "\e933"; }

.ic-telegram:before {
  content: "\e927"; }

.ic-facebook:before {
  content: "\e92a"; }

.ic-twitter:before {
  content: "\e92b"; }

.ic-owlweb:before {
  content: "\e92c"; }

.ic-enterprise2:before {
  content: "\e925"; }

.ic-eye-crossed:before {
  content: "\e926"; }

.ic-phone1:before {
  content: "\e928"; }

.ic-uzer:before {
  content: "\e929"; }

.ic-attention-in-round:before {
  content: "\e924"; }

.ic-time:before {
  content: "\e93a"; }

.ic-round-check:before {
  content: "\e923"; }

.ic-home:before {
  content: "\e920"; }

.ic-setings:before {
  content: "\e921"; }

.ic-comp-gear:before {
  content: "\e922"; }

.ic-bell:before {
  content: "\e912"; }

.ic-bill:before {
  content: "\e913"; }

.ic-consume:before {
  content: "\e914"; }

.ic-enterprise:before {
  content: "\e915"; }

.ic-exit:before {
  content: "\e916"; }

.ic-phone:before {
  content: "\e917"; }

.ic-reports:before {
  content: "\e918"; }

.ic-shop:before {
  content: "\e919"; }

.ic-user:before {
  content: "\e91a"; }

.ic-days:before {
  content: "\e939"; }

.ic-eye:before {
  content: "\e91c"; }

.ic-leftarrow:before {
  content: "\e911"; }

.ic-phoneshape:before {
  content: "\e91d"; }

.ic-lock:before {
  content: "\e91e"; }

.ic-mail:before {
  content: "\e91f"; }

.ic-cons:before {
  content: "\e910"; }

.ic-arrow:before {
  content: "\e92d"; }

.ic-grivna:before {
  content: "\e92e"; }

.ic-Shape:before {
  content: "\e92f"; }

.ic-search:before {
  content: "\e91b"; }

.ic-right-info-btn:before {
  content: "\e900"; }

.ic-alerticon:before {
  content: "\e901"; }

.ic-anketload:before {
  content: "\e902"; }

.ic-attention:before {
  content: "\e903"; }

.ic-brdcmparrow:before {
  content: "\e904"; }

.ic-editbtn:before {
  content: "\e905"; }

.ic-electro:before {
  content: "\e906"; }

.ic-filtertoggle:before {
  content: "\e907"; }

.ic-gas:before {
  content: "\e908"; }

.ic-download2:before {
  content: "\e909"; }

.ic-ok-in-round:before {
  content: "\e90a"; }

.ic-print:before {
  content: "\e90b"; }

.ic-keyfileinput:before {
  content: "\e90c"; }

.ic-payarrows:before {
  content: "\e90d"; }

.ic-questioinico:before {
  content: "\e90e"; }

.ic-arrowtoleft:before {
  content: "\e90f"; }

@font-face {
  font-family: 'megawatt2';
  src: url("../icofonts/megawatt2.eot?bgav4b");
  src: url("../icofonts/megawatt2.eot?bgav4b#iefix") format("embedded-opentype"), url("../icofonts/megawatt2.ttf?bgav4b") format("truetype"), url("../icofonts/megawatt2.woff?bgav4b") format("woff"), url("../icofonts/megawatt2.svg?bgav4b#megawatt2") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="ic2-"], [class*=" ic2-"],
[class^="ic2-"] *:before, [class*=" ic2-"] *:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'megawatt2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ic2-for-home-fill:before {
  content: "\e92b"; }

.ic2-bussiness-fill:before {
  content: "\e92c"; }

.ic2-mail:before {
  content: "\e92a"; }

.ic2-for-home-source:before {
  content: "\e928"; }

.ic2-bussiness-source:before {
  content: "\e929"; }

.ic2-fileico:before {
  content: "\e927"; }

.ic2-gasbalon:before {
  content: "\e925"; }

.ic2-ic-law:before {
  content: "\e924"; }

.ic2-agr-gas .path1:before {
  content: "\e91e";
  color: #0e8aa9; }

.ic2-agr-gas .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: #00bad5; }

.ic2-agr-el .path1:before {
  content: "\e920";
  color: #fdae02; }

.ic2-agr-el .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: #fdcb02; }

.ic2-chartsallpoints:before {
  content: "\e922"; }

.ic2-chartscupoint:before {
  content: "\e923"; }

.ic2-agreements:before {
  content: "\e91d"; }

.ic2-triangledown:before {
  content: "\e91a"; }

.ic2-year-next:before {
  content: "\e91b"; }

.ic2-year-prew:before {
  content: "\e91c"; }

.ic2-marker:before {
  content: "\e926"; }

.ic2-charts-pen:before {
  content: "\e919"; }

.ic2-play:before {
  content: "\e909"; }

.ic2-about .path1:before {
  content: "\e901";
  color: #666666; }

.ic2-about .path2:before {
  content: "\e902";
  margin-left: -2.5380859375em;
  color: #fcd104; }

.ic2-chewron-down:before {
  content: "\e911"; }

.ic2-fat-check:before {
  content: "\e912"; }

.ic2-fop:before {
  content: "\e90b"; }

.ic2-gerb:before {
  content: "\e90c"; }

.ic2-ic-1:before {
  content: "\e903"; }

.ic2-ic-2:before {
  content: "\e904"; }

.ic2-ic-3:before {
  content: "\e905"; }

.ic2-ic-4:before {
  content: "\e906"; }

.ic2-ic-blog:before {
  content: "\e916"; }

.ic2-ic-calculator:before {
  content: "\e915"; }

.ic2-ic-day .path1:before {
  content: "\e90d";
  color: #32325d;
  opacity: 0.3; }

.ic2-ic-day .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: #06a6f4; }

.ic2-ic-el:before {
  content: "\e907"; }

.ic2-ic-exit:before {
  content: "\e917"; }

.ic2-ic-faq:before {
  content: "\e914"; }

.ic2-ic-gas:before {
  content: "\e908"; }

.ic2-ic-month .path1:before {
  content: "\e90f";
  color: #32325d;
  opacity: 0.3; }

.ic2-ic-month .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: #06a6f4; }

.ic2-ic-tariff:before {
  content: "\e913"; }

.ic2-ic-user:before {
  content: "\e918"; }

.ic2-inst:before {
  content: "\e900"; }

.ic2-urface:before {
  content: "\e90a"; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: sans-serif; }

body {
  margin: 0; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

h2 {
  font-size: 1.5em;
  margin: .83em 0; }

h3 {
  font-size: 1.17em;
  margin: 1em 0; }

h4 {
  font-size: 1em;
  margin: 1.33em 0; }

h5 {
  font-size: .83em;
  margin: 1.67em 0; }

h6 {
  font-size: .75em;
  margin: 2.33em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

p, pre {
  margin: 1em 0; }

code, kbd, pre, samp {
  font-family: monospace,serif;
  _font-family: 'courier new',monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

dl, menu, ol, ul {
  margin: 1em 0; }

dd {
  margin: 0 0 0 40px; }

menu, ol, ul {
  padding: 0 0 0 0px; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.up {
  text-transform: uppercase; }

@media (max-width: 1280px) {
  .calculator-l * {
    position: relative;
    z-index: 10; }
  .calc-arrow {
    z-index: 1; }
  .chooseUs-r {
    width: 90%;
    padding-left: 10%; }
  .blog-item .description {
    overflow-y: hidden;
    z-index: 10; }
    .blog-item .description::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 20px;
      z-index: 11;
      background-color: #fff;
      bottom: 0;
      left: 0;
      box-shadow: 0px 0px 20px 20px #fff; } }

@media (max-width: 1024px) {
  .about-page {
    overflow-y: scroll;
    height: 100vh; }
    .about-page .r-col {
      height: auto;
      flex-direction: column; }
      .about-page .r-col .r, .about-page .r-col .l {
        width: 100%; }
      .about-page .r-col .r {
        height: auto;
        overflow-y: unset; }
    .about-page .dots {
      display: none; }
    .about-page .log-reg-about {
      position: static;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center; }
    .about-page .one-scroll__slide {
      padding: 5%; }
  .l-col {
    width: 100%;
    flex-direction: row; }
  .r-col {
    width: 100%;
    margin-left: 0;
    padding-top: 64px; }
  .main .logo {
    display: none; }
  .advantages, .client, .aboutUs, .blog {
    padding-top: 125px;
    padding-bottom: 125px; }
  .section-title {
    font-size: 26px; }
  .section-ul li {
    margin-bottom: 15px; } }

@media (max-width: 991px) {
  .full-width-block .container {
    flex-direction: column; }
  .blog-item .btn {
    width: auto;
    padding: 10px;
    justify-content: space-around;
    border: 1px solid #FCD100;
    color: #000; }
    .blog-item .btn .text {
      opacity: 1;
      display: block; }
    .blog-item .btn .ic-arrow {
      display: none; }
  .blog-item__title {
    font-size: 17px; }
  .footer-r {
    text-align: center;
    margin-top: 61px; }
  .footer-r__ul .subtitle {
    max-width: unset; }
  .footer-r__ul .social {
    justify-content: center; }
  .footer-r__ul .title::before {
    left: 50%;
    transform: translateX(-50%);
    right: 0; }
  .footer #footer-form .input-wrap, #footer-form .input-wrap {
    width: 100%; }
  .advantages-item {
    margin-bottom: 30px; }
  .blog-item .description {
    height: 169px; }
  .calc .calculator-l__title {
    text-align: center; } }

@media (min-width: 768px) and (max-width: 991px) {
  .calc .calculator-l .tab-content .tab-item .calc-desc, input[type=range], .calc .calculator-l .tab-content .tab-item .input-wr, .calc .calculator-l .tab-title {
    width: 90%; } }

@media (max-width: 768px) {
  .blog-item:hover .btn {
    width: auto; }
  .calculator-wrapper {
    flex-direction: column; }
  .calc .calculator-l, .calc .calculator-r {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
  .calc .calculator-l .tab-title .tab {
    font-size: 12px; }
  .calc .calculator-l .calc-arrow {
    top: 100%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%) rotate(90deg); }
  .calc .calculator-l .tab-content .tab-item .input-wr::before {
    position: static; }
  .about2-inner {
    margin-left: 0; }
  .about2 .section-title {
    font-size: 20px; }
  .about2 .item {
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px; }
  .cust-p {
    padding-left: 60px;
    padding-right: 60px; }
  .base {
    flex-direction: column;
    height: auto;
    background-color: transparent; }
    .base .right {
      padding: 15px 5px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-evenly;
      margin-top: 25px;
      background-color: #f6f6f6;
      border-radius: 50px; }
  .bottom-m {
    width: 64px;
    height: 64px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0; }
    .bottom-m span {
      font-size: 20px;
      margin-right: 0; }
  .advantages-item {
    margin-bottom: 15px;
    margin-top: 15px; }
  .advantages, .client, .aboutUs, .blog {
    padding-top: 125px;
    padding-bottom: 125px; }
  .chooseUs {
    padding-bottom: 37px;
    padding-top: 90px; }
  .calc .calculator-l .tab-title {
    width: 100%; }
  .tab-content, .calc .calculator-l .tab-content .tab-item .calc-input, .calc .calculator-l .tab-content .tab-item, .calc .calculator-l .tab-content .tab-item .calc-desc {
    width: 100%; }
  input[type=range] {
    width: 90%; }
  .calc .calculator-l, .calc .calculator-r {
    padding-left: 20px;
    padding-right: 20px; }
  .calc .calculator-l * {
    position: relative;
    z-index: 10; }
  .calc .calc-arrow {
    z-index: 1;
    right: -15% !important; }
  .btn-anim-footer {
    margin-left: auto; }
  .section-title {
    font-size: 25px; }
  .blog-item__title {
    font-size: 17px; }
  .calc .calculator-l .tab-content .tab-item .calc-desc {
    display: none; } }

@media (max-width: 767px) {
  .blog-item, .blog-item .description {
    height: auto; }
  .blog-item .description::before, .blog-item .btn {
    position: static; } }

@media (max-width: 680px) {
  box-sizing: border-box; }

@media (max-width: 576px) {
  .calc .calculator-l__title {
    font-size: 18px; }
  .calc .calculator-r__text .calc-title {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 34px; }
  .calc .calculator-r__text .final-price, .calc .calculator-r__text .economy {
    font-size: 24px; }
  .calc .calculator-r__text .economy, .calc .calculator-r__text {
    text-align: center; }
  body section {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .timeline-item__number {
    font-size: 38px; }
  .t-col {
    flex-direction: column;
    align-items: center; }
    .t-col ul {
      width: 100%; }
  .slide__ul li {
    flex-direction: column;
    align-items: flex-end; }
  .cust-p {
    padding: 0; }
  .about2 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .about2-inner {
    margin-left: 0;
    padding: 0px 10px; }
    .about2-inner .item {
      padding: 40px 20px; }
      .about2-inner .item .btn {
        width: 218px; }
        .about2-inner .item .btn .text {
          opacity: 1;
          margin-right: 35px;
          display: block; }
  .blog .col-12.col-md-4:not(:first-of-type) {
    display: none; }
  .btn-anim {
    margin-top: 20px;
    margin-right: auto; }
  .main {
    text-align: center; }
  .main-text p {
    font-size: 15px;
    line-height: 180%; }
    .main-text p b {
      font-size: 22px; }
  .section-h1 {
    font-size: 22px; }
  .section-h2 {
    font-size: 18px; }
  .advantages-item img {
    width: 90px;
    height: 90px; }
  .advantages-item {
    padding-top: 16px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px; }
  .advantages-item__title {
    font-size: 14px; }
  .section-title {
    font-size: 22px; }
  .section-ul li {
    font-size: 14px; }
  .btn {
    white-space: nowrap; }
  .aboutUs {
    padding-top: 46px;
    padding-bottom: 20px; }
  .client {
    padding-top: 52px;
    padding-bottom: 103px; }
  .timeline {
    flex-direction: column; }
    .timeline-item {
      width: 100%;
      padding-left: 60px; }
  .timeline-item .circle {
    top: -55px;
    left: -60px; }
    .timeline-item .circle::before {
      display: none; }
    .timeline-item .circle::after {
      color: #FCD100; }
  .timeline-item__number {
    color: #FCD100;
    max-width: unset; }
  .timeline-item__desc {
    margin-top: -45px;
    font-size: 14px; }
    .timeline-item__desc a {
      font-size: 14px; }
  .chooseUs-r, .chooseUs-l {
    width: 100%;
    padding: 0; }
  .chooseUs-r {
    padding: 0px 18px;
    margin-top: 40px; }
  .chooseUs-r__ul .name {
    font-size: 18px; }
  .chooseUs-r__ul .description {
    font-size: 14px; }
  .chooseUs-r__ul li {
    margin-top: 30px; }
  .full-width-block .container {
    flex-direction: column; }
    .full-width-block .container .section-title {
      font-size: 16px;
      text-align: center; }
  .btn-b {
    width: 100%;
    text-align: center;
    font-size: 13px; }
  .blog .section-title {
    font-size: 18px; }
  .footer-r {
    text-align: center;
    margin-top: 61px; }
  .footer-r__ul .subtitle {
    max-width: unset; }
  .footer-r__ul .social {
    justify-content: center; }
  .footer-r__ul .title::before {
    left: 50%;
    transform: translateX(-50%);
    right: 0; }
  .footer #footer-form .input-wrap, #footer-form .input-wrap {
    width: 100%; } }

@media (max-width: 425px) {
  .section-h4::before {
    top: unset;
    bottom: -5px; }
  .modal-video::before {
    top: 30%; } }

@media (max-width: 320px) {
  .calc .calculator-l .tab-title .tab {
    font-size: 9px; }
  .advantages .col-6 {
    padding-left: 5px;
    padding-right: 5px; } }
